import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiSearch } from 'react-icons/bi';
import { FaRegHeart, FaBagShopping } from 'react-icons/fa6';
import NavMenus from './NavMenus';
import { useAuth } from '../user/AuthContext';
import { useCart } from '../CartContext';

const NavTop = () => {
  const { userToken, logout } = useAuth(); // Use AuthContext
  const [cartItems, setCartItems] = useState([]); // Mock state for cart items
  const [wishlistItems, setWishlistItems] = useState([]); // Mock state for wishlist items
  const { token } = useCart();

  useEffect(() => {

      fetch(`https://store.clothologyglobal.co.in/api/v1/get_customer_cart`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => res.json())
        .then((data) => {
          if(data.status){
            setCartItems(data.cart_items);
          }
        })
        .catch((err) => console.error('Error fetching cart:', err));

        fetch(`https://store.clothologyglobal.co.in/api/v1/get_customer_wishlist`,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => res.json())
        .then((data) => {
           if(data.status){
              setCartItems(data.wishlist);
            }
        })
        .catch((err) => console.error('Error fetching wishlist:', err));
    }, []);

  const cartCount = cartItems.length;
  const wishlistCount = wishlistItems.length;
  console.log(wishlistCount);

  return (
    <>
      {/* Header */}
      <div className="bg-black">
        <div className="max-w-7xl mx-auto justify-between p-4 flex items-center text-white h-3 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center gap-3 capitalize">
            <Link className="text-xs text-slate-300">Offers</Link>
            <Link className="text-xs text-slate-300">Our fanbook</Link>
            <Link className="text-xs text-slate-300">Clotribes membership</Link>
            <Link className="text-xs text-slate-300">Find a store near me</Link>
          </div>
          <div className="flex items-center gap-3 capitalize">
            <Link className="text-xs text-slate-300">Contact us</Link>
            <Link className="text-xs text-slate-300">Track your order</Link>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <header className="bg-white border-b border-black z-50 w-full sticky top-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 relative">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img
                  onClick={() => {
                    window.location.href = process.env.PUBLIC_URL || '/';
                  }}
                  className="w-60 h-30 cursor-pointer"
                  src={`${process.env.PUBLIC_URL || ''}/images/logo.png`}
                  alt="Logo"
                />
              </div>
              <NavMenus />
            </div>
            <div className="flex items-center relative group">
              <input
                type="text"
                placeholder="Search"
                className="block w-full pl-3 py-2 pr-12 sm:text-sm rounded-[4px] border-[0.2px] border-black border-opacity-20 focus:outline-none focus:border-opacity-80"
              />
              <button className="absolute right-3 text-black group-hover:text-black transition duration-1000 ease-in-out">
                <BiSearch />
              </button>
            </div>
            <div className="flex items-center gap-5">
              {userToken ? (
                <div className="flex items-center gap-3">
                  <span className="font-bold text-gray-800">Hello, <Link to={'/u/profile'}>User</Link></span>
                  <button
                    onClick={logout}
                    className="text-red-500 hover:text-red-600 font-semibold"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <Link to="/login">LOGIN / SIGNUP</Link>
              )}

              <Link to="/wishlist" className="relative">
                <FaRegHeart />
                {wishlistCount > 0 && (
                  <span className="absolute top-[-8px] right-[-8px] text-xs text-white bg-red-500 rounded-full w-5 h-5 flex items-center justify-center">
                    {wishlistCount}
                  </span>
                )}
              </Link>

              <Link to="/cart" className="relative">
                <FaBagShopping />
                {cartCount > 0 && (
                  <span className="absolute top-[-8px] right-[-8px] text-xs text-white bg-blue-500 rounded-full w-5 h-5 flex items-center justify-center">
                    {cartCount}
                  </span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavTop;
