import React from "react";

import NavTop from '../components/layoutParts/NavTop';
import Footer from '../components/layoutParts/Footer';

const MainLayOut = ({children}) =>{
    return(
        <>
        <NavTop/>
        <div>
            {children}
        </div>
        <Footer/>
        </>
    )
}

export default MainLayOut;