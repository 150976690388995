import React, { createContext, useState, useContext, useEffect } from "react";
import API_BASE_URL from './../apiConfig';

const CartContext = createContext();

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {

      const [cart, setCart] = useState([]);
      const [products, setProducts] = useState([]);
      const [loading, setLoading] = useState(false);
      // const [error, setError] = useState(null);
      // const [total, setTotal] = useState(0);
      // const [quantity, setQuantity] = useState(0);
      // const [showCart, setShowCart] = useState(false);
      // const [showCheckout, setShowCheckout] = useState(false);
      // const [showOrder, setShowOrder] = useState(false);
      // const [showOrderDetails, setShowOrderDetails] = useState(false);
      // const [showOrderSummary, setShowOrderSummary] = useState(false);
      // const [showOrderSummaryDetails, setShowOrderSummaryDetails] = useState(false);


      const [token, setToken] = useState(() => {
        return localStorage.getItem("userToken") || localStorage.getItem("webToken");
      });

      useEffect(() => {
        const fetchProducts = async () => {

          setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Add 3-second delay
      try {
        const response = await fetch(`${API_BASE_URL}/get_active_products`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status) {
          setProducts(data.products);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
        //     const response = await fetch(`${API_BASE_URL}/get_active_products`, {
        //       headers: {
        //         Authorization: `Bearer ${token}`,
        //       },
        //     });
        //     const data = await response.json();
        //     setProducts(data);
        };

        fetchProducts();
    }, [token]);


  const [cartItems, setCartItems] = useState(() => {
    return JSON.parse(localStorage.getItem('cartItems')) || [];
  });
  const [wishlistItems, setWishlistItems] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('wishlistItems')) || [];
    } catch {
      return [];
    }
  });
  localStorage.removeItem('wishlistItems');
  const [savedItems, setSavedItems] = useState(() => {
    return JSON.parse(localStorage.getItem('savedItems')) || [];
  });

  // const [token, setToken] = useState(() => {
  //   return localStorage.getItem("userToken") || localStorage.getItem("webToken");
  // });

  const [apiurl, setApiurl] = useState(() => {
    return localStorage.getItem("apiurl");
  });

  // Sync cartItems to localStorage
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  // Sync wishlistItems to localStorage
  useEffect(() => {
    localStorage.setItem("wishlistItems", JSON.stringify(wishlistItems));
  }, [wishlistItems]);

  // Sync savedItems to localStorage
  useEffect(() => {
    localStorage.setItem("savedItems", JSON.stringify(savedItems));
  }, [savedItems]);

  // Add item to cart or increase quantity if already in cart
  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const itemExists = prevItems.find((item) => item.id === product.id);
      if (itemExists) {
        return prevItems.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
  };

  // Add item to wishlist
  const addToWishlist = (product) => {
    setWishlistItems((prevItems) => {
      if (Array.isArray(prevItems) && !prevItems.find((item) => item.id === product.id)) {
        return [...prevItems, product];
      }
      return Array.isArray(prevItems) ? prevItems : []; // Ensure it returns a valid array
    });
  };
  

  // Remove item from wishlist
  const removeFromWishlist = (productId) => {
    setWishlistItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
  };

  // Increase quantity of a specific item in cart
  const increaseQuantity = (productId) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  // Decrease quantity of a specific item in cart
  const decreaseQuantity = (productId) => {
    setCartItems((prevItems) =>
      prevItems
        .map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0) // Remove items with quantity 0
    );
  };

  // Save item for later
  const saveForLater = (productId) => {
    setCartItems((prevItems) => {
      const itemToSave = prevItems.find((item) => item.id === productId);
      if (itemToSave) {
        setSavedItems((prevSaved) => [...prevSaved, itemToSave]);
      }
      return prevItems.filter((item) => item.id !== productId); // Remove from cart
    });
  };

  // Compute counts
  const cartCount = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const wishlistCount = wishlistItems.length;

  const totalAmount = cartItems.reduce((total, item) => total + item.sale_price * item.quantity, 0);

  // Add totalMRP, subtotal, and savings to the context
  const totalMRP = cartItems.reduce((acc, item) => acc + item.regular_price * item.quantity, 0);
  const subtotal = cartItems.reduce((acc, item) => acc + item.sale_price * item.quantity, 0);
  const savings = totalMRP - subtotal;

  // Logout functionality
  const logout = () => {
    setToken("webToken");
    localStorage.removeItem("userToken");
  };

  return (
    <CartContext.Provider
      value={{
        products,
        cartItems,
        wishlistItems,
        savedItems,
        cartCount,
        wishlistCount,
        token,
        apiurl,
        addToCart,
        addToWishlist,
        removeFromWishlist,
        increaseQuantity,
        decreaseQuantity,
        saveForLater,
        totalAmount,
        totalMRP, // Add totalMRP to context
        subtotal, // Add subtotal to context
        savings, // Add savings to context
        logout,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
