import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import { Button, Container, Box, Typography, Alert } from '@mui/material';
import MainLayOut from '../../layout/MainLayOut';

const Login = () => {
  const [error, setError] = useState(null);
  const { login } = useAuth();


  // https://store.clothologyglobal.co.in/api/v1/webuser/login

  const loginUser = () => {
    const userToken = process.env.REACT_APP_USER_TOKEN;
    if (userToken) {
      login(userToken);
    } else {
      setError('Login failed');
    }
  };

  return (

    <MainLayOut>
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Box
        sx={{
          p: 4,
          borderRadius: '8px',
          boxShadow: 3,
          backgroundColor: 'white',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome Back!
        </Typography>
        <Typography variant="body1" color="text.secondary" mb={3}>
          Click the button below to log in to your account.
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        <Button
          variant="contained"
          color="primary"
          onClick={loginUser}
          sx={{
            px: 4,
            py: 1.5,
            fontSize: '1rem',
            borderRadius: '30px',
            textTransform: 'none',
          }}
        >
          Login
        </Button>
      </Box>
    </Container>
  </MainLayOut>
    // <Container>


    //   <Box>
    //     <Typography>Login</Typography>
    //     {error && <Alert severity="error">{error}</Alert>}
    //     <Button onClick={loginUser}>Login</Button>
    //   </Box>
    // </Container>
  );
};

export default Login;






// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import MainLayOut from '../../layout/MainLayOut';

// const Login = () => {
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const loginUser = () => {
//     const token = process.env.REACT_APP_USER_TOKEN;

//     try {
//       if (token) {
//         localStorage.setItem('userToken', token);
//         console.log('Login successful');
//         navigate('/wishlist');
//       } else {
//         setError('Login failed');
//         console.log('Login failed');
//       }
//     } catch (err) {
//       setError('An error occurred');
//       console.error('Error:', err);
//     }
//   };

//   useEffect(() => {
//     const storedToken = localStorage.getItem('userToken');
//     if (storedToken) {
//       navigate('/wishlist'); 
//     }
//   }, [navigate]);

//   return (
//     <MainLayOut>
//       <div>
//         <h2>Click Here to Login</h2>
//         <button onClick={loginUser}>Login</button>
//         {error && <p style={{ color: 'red' }}>{error}</p>}
//       </div>
//     </MainLayOut>
//   );
// };

// export default Login;






/*import React, { useState } from 'react';
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MainLayOut from '../../layout/MainLayOut';

const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // State to store error messages

  const loginUser = async () => {
    try {
      const response = await fetch('https://kyletest.in/clothology/backend/index.php/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        // Handle HTTP errors (e.g., 400, 401, 500)
        const errorData = await response.json();
        setError(errorData.error || 'An unknown error occurred');
        console.error('Login error:', errorData.error);
        return;
      }

      const data = await response.json();
      if (data.token) {
        localStorage.setItem('userToken', data.token); // Store the user-specific JWT
        console.log('Login successful');
        window.location.href = 'https://tclserver.ertru.uk/api/get_publish_products';
      } else {
        setError('Login failed');
        console.log('Login failed');
      }
    } catch (err) {
      // Handle network errors
      setError('Failed to fetch: ' + err.message);
      console.error('Fetch error:', err);
    }
  };



  return (
    <div>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={loginUser}>Login</button>
      {error && <p style={{ color: 'red' }}>{error}</p>} 
    </div>
  );
};



export default Login;*/
