import React, { useState } from 'react';
import { useCart } from '../components/CartContext';
import { useNavigate } from 'react-router-dom';
import MainLayOut from '../layout/MainLayOut';
import { Grid } from 'swiper/modules';

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems, totalMRP, subtotal, savings } = useCart(); // Access data from context

  const [shippingDetails, setShippingDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });

  const [paymentMethod, setPaymentMethod] = useState('creditCard');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSubmit = () => {
    if (!shippingDetails.name || !shippingDetails.email || !shippingDetails.address) {
      alert('Please fill in all required fields!');
      return;
    }

    // Here, you would typically send the form data to the backend for processing
    alert('Order placed successfully!');
    navigate('/thank-you'); // Redirect to a thank you page
  };

  return (
    <MainLayOut>
      <div className="checkout-page p-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-semibold mb-6">Checkout</h2>
<div className='flex w-full gap-2'>
<div className='w-1/2'>

            {/* Shipping Details */}
            <div className="shipping-details mb-6 p-4 rounded-lg pe-5">
            <form>
                <div className="mb-4">          
                    <label className="block text-xs font-semibold mb-3">EMAIL</label>
                    <input
                        type="email"
                        name="email"
                        value={shippingDetails.email}
                        onChange={handleChange}
                        className="w-full px-4 py-1 border"
                        placeholder="Enter your email"
                    />
                </div>
                <h3 className="text-sm font-semibold mb-4">SHIPPING</h3>
            
                <div className="mb-4">
                <label className="block text-sm font-semibold">First Name</label>
                <input
                    type="text"
                    name="name"
                    value={shippingDetails.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="First Name"
                />
                </div>     
                <div className="mb-4">
                <label className="block text-sm font-semibold">Last Name</label>
                <input
                    type="text"
                    name="name"
                    value={shippingDetails.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Last Name"
                />
                </div>      

                <div className="mb-4">
                <label className="block text-sm font-semibold">Phone Number</label>
                <input
                    type="tel"
                    name="phone"
                    value={shippingDetails.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Phone number"
                />
                </div>

                <div className="mb-4">
                <label className="block text-sm font-semibold">Address</label>
                <textarea
                    name="address"
                    value={shippingDetails.address}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Address"
                />
                </div>

                <div className="mb-4">
                <label className="block text-sm font-semibold">City</label>
                <input
                    type="text"
                    name="city"
                    value={shippingDetails.city}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="City"
                />
                </div>   

                <div className="mb-4">
                <label className="block text-sm font-semibold">State</label>
                <input
                    type="text"
                    name="state"
                    value={shippingDetails.state}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="State"
                />
                </div>   

                <div className="mb-4">
                <label className="block text-sm font-semibold">ZipCode</label>
                <input
                    type="text"
                    name="zipcode"
                    value={shippingDetails.zipcode}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Zip Code"
                />
                </div>   
            </form>
            </div>

            {/* Payment Method */}
            <div className="payment-method mb-6 p-4 border rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">PAYMENT METHOD</h3>
            <div className="payment-options flex items-center gap-6">
                <label className="flex items-center">
                <input
                    type="radio"
                    value="creditCard"
                    checked={paymentMethod === 'creditCard'}
                    onChange={handlePaymentMethodChange}
                    className="mr-2"
                />
                Credit Card
                </label>

                <label className="flex items-center">
                <input
                    type="radio"
                    value="paypal"
                    checked={paymentMethod === 'paypal'}
                    onChange={handlePaymentMethodChange}
                    className="mr-2"
                />
                PayPal
                </label>

                <label className="flex items-center">
                <input
                    type="radio"
                    value="cod"
                    checked={paymentMethod === 'cod'}
                    onChange={handlePaymentMethodChange}
                    className="mr-2"
                />
                Cash on Delivery
                </label>
            </div>
            </div>
        </div>
        <div className='w-1/2'>
            {/* Order Summary */}
            <div className="order-summary p-4 border rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
            <div className="mb-4">
                <p className="text-gray-600">Total MRP (Incl. of taxes): ₹{totalMRP}</p>
                <p className="text-gray-600">Bag Discount: -₹{savings}</p>
                <p className="text-gray-600">Delivery Fee: Free</p>
            </div>
            <div className="font-bold text-xl text-black mb-4">
                Subtotal: ₹{subtotal}
            </div>
            <div className="text-green-600">You are saving a total of ₹{savings} on this order</div>
            </div>

            {/* Confirm Order Button */}
            <button
            onClick={handleSubmit}
            className="w-full mt-6 bg-blue-500 text-white py-3 rounded-lg"
            >
            Confirm Order
            </button>
        </div>
</div>
        
      </div>
    </MainLayOut>
  );
};

export default Checkout;
