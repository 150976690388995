import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CartProvider } from './components/CartContext';
import App from './App';
// import { StrictMode } from 'react';

function Root() {
  return (
    // <StrictMode>
    <Router>
      <CartProvider>
        <App />
      </CartProvider>
    </Router>
    // </StrictMode>
  );
}

export default Root;
