import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCart } from './CartContext';

function ProductSizeGuide() {
  const [colors, setColors] = useState([]);
  const [productVariations, setProductVariations] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [newContent, setNewContent] = useState('');
  const { addToCart, token } = useCart();
  const navigate = useNavigate();
  const { slug } = useParams(); // Assuming slug is part of the route (e.g., /product/:slug)

  useEffect(() => {
    fetch('https://tclserver.ertru.uk/api/v1/get_active_products', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          const variations = data.products.flatMap((product) => product.product_variations || []);
          console.log('Product Variations:', variations);
  
          setProductVariations(variations);
  
          const uniqueColors = [
            ...new Map(
              variations.map((v) => [v.color, { name: v.color, color_id: v.color_id }])
            ).values(),
          ];
  
          const uniqueSizes = [
            ...new Map(
              variations.map((v) => [v.size, { name: v.size, size_id: v.size_id }])
            ).values(),
          ];
  
          setColors(uniqueColors);
          setSizes(uniqueSizes);
  
          if (uniqueColors.length > 0) setSelectedColor(uniqueColors[0].name);
          if (uniqueSizes.length > 0) setSelectedSize(uniqueSizes[0].name);
        }
      })
      .catch((error) => console.error('Error fetching product data:', error));
  }, [token]);
  

  const handleColorChange = (color) => {
    setSelectedColor(color);
  
    // Find the first variation with the selected color and current size
    const matchingVariation = productVariations.find(
      (variation) =>
        variation.color === color && variation.size === selectedSize
    );
  
    if (matchingVariation) {
      const newSlug = matchingVariation.slug;
      navigate(`/p/${newSlug}`);
      window.location.reload();
    }
  };
  
  const handleSizeChange = (size) => {
    setSelectedSize(size);

    // Find the first variation with the selected size and current color
    const matchingVariation = productVariations.find(
      (variation) => variation.size === size && variation.color === selectedColor
    );

    if (matchingVariation) {
      setNewContent(matchingVariation.createContent); // Update state with new content
    } else {
      setNewContent(''); // Clear content if no match
    }
  };
  

  return (
    <div className="product-size-guide bg-white rounded-lg p-4">
      {/* Color Selection */}
      <div className="w-full sm:w-1/3 mt-3 relative">
        <div className="flex justify-between items-center mb-2">
          <span className="text-gray-800 font-semibold">Colour Options:</span>
          <span className="text-gray-600">{selectedColor}</span>
        </div>

        <div className="mt-2 flex gap-2">
          {colors.map((color) => (
            <button
              key={color.color_id}
              className={`cursor-pointer w-10 h-10 rounded-full border-2 ${
                selectedColor === color.name
                  ? 'border-blue-500 ring-2 ring-blue-500'
                  : 'border-gray-300'
              }`}
              style={{ backgroundColor: color.name }}
              onClick={() => handleColorChange(color.name)}
            />
          ))}
        </div>
      </div>

      {/* Size Selection */}
      <div className="mt-4">
      <label className="block text-gray-800 font-semibold">Select Size</label>
      <div className="mt-2 flex gap-2">
        {sizes.map((size) => (
          <label
            key={size.size_id}
            className={`cursor-pointer relative w-16 h-16 border-2 rounded-sm flex items-center justify-center transition-all duration-200 ease-in-out ${
              selectedSize === size.name ? 'border-blue-500 ring-2 ring-blue-500' : 'border-gray-300'
            }`}
          >
            <input
              type="radio"
              name="size"
              value={size.name}
              checked={selectedSize === size.name}
              onChange={() => handleSizeChange(size.name)}
              className="absolute opacity-0"
            />
            <div className="flex items-center justify-center w-full h-full text-center font-semibold">
              {size.name}
            </div>
          </label>
        ))}
      </div>
      {newContent && (
        <p className="mt-2 text-gray-600 font-medium">
          Create Content on new Selected product: {newContent}
        </p>
      )}
    </div>
    </div>
  );
}

export default ProductSizeGuide;
