import React from 'react';
import { useParams } from 'react-router-dom';
import ProductPageBody from '../components/ProductPageBody';
import MainLayOut from '../layout/MainLayOut';


function ProductPage({ showAll }) {
  const { categorySlug } = useParams(); // Retrieve categorySlug from URL

  return(
    <MainLayOut>
      <ProductPageBody categorySlug={showAll ? null : categorySlug} />;
    </MainLayOut>
  ) 
}

export default ProductPage;