import React, { Suspense } from 'react';
import { useInView } from 'react-intersection-observer';

// Lazy load components
const HeyDeals = React.lazy(() => import('./ProductSliderHome'));
const ProductCategoryHome = React.lazy(() => import('./ProductCategoryHome'));
const SuperCombedUnder = React.lazy(() => import('./SuperCombedUnder499'));
const WardrobeMin45Off = React.lazy(() => import('./WardrobeMin45Off'));
const StealNow = React.lazy(() => import('./StealNow'));
const SuperCombedUnder399 = React.lazy(() => import('./SuperCombedUnder399'));
const OversizedUnder499 = React.lazy(() => import('./OversizedUnder499'));
const CloSaving49Off = React.lazy(() => import('./CloSaving49Off'));
const CloSignatureTeesStartAt999 = React.lazy(() =>
  import('./CloSignatureTeesStartAt999')
);
const JacquardCottonTeesStartAt299 = React.lazy(() =>
  import('./JacquardCottonTeesStartAt299')
);
const ShopByCategoryMen = React.lazy(() => import('./ShopByCategoryMen'));
const ShopByCategoryWomen = React.lazy(() => import('./ShopByCategoryWomen'));

const LazyComponent = ({ component: Component }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Load when 10% of the component is visible
    triggerOnce: true, // Only load once
  });

  return (
    <div ref={ref}>
      {inView && (
        <Suspense fallback={<div>Loading...</div>}>
          <Component />
        </Suspense>
      )}
    </div>
  );
};

const HomeBody = () => {
  return (
    <>
      <img
        src={`${process.env.PUBLIC_URL}/images/Poster1.jpeg`}
        className="w-full"
        alt="Poster1"
      />
      <LazyComponent component={HeyDeals} />
      <img
        src={`${process.env.PUBLIC_URL}/images/Poster2.jpeg`}
        className="w-full"
        alt="Poster2"
      />
      <LazyComponent component={ProductCategoryHome} />
      <LazyComponent component={SuperCombedUnder} />
      <LazyComponent component={WardrobeMin45Off} />
      <LazyComponent component={StealNow} />
      <LazyComponent component={SuperCombedUnder399} />
      <LazyComponent component={OversizedUnder499} />
      <LazyComponent component={CloSaving49Off} />
      <LazyComponent component={CloSignatureTeesStartAt999} />
      <LazyComponent component={JacquardCottonTeesStartAt299} />
      <LazyComponent component={ShopByCategoryMen} />
      <LazyComponent component={ShopByCategoryWomen} />
      <img
        src={`${process.env.PUBLIC_URL}/images/Poster3.jpeg`}
        className="w-full"
        alt="Poster3"
      />
    </>
  );
};

export default HomeBody;
