import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MainLayOut from '../layout/MainLayOut';
import API_BASE_URL from '../apiConfig';

const Cart = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = process.env.REACT_APP_USER_TOKEN;

  // Fetch Cart Data
  const fetchCart = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/get_customer_cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API Response:', response.data.cart_items);
      setCartItems(Array.isArray(response.data.cart_items) ? response.data.cart_items : []);
    } catch (error) {
      console.error('Error fetching cart:', error.response?.data?.message || error.message);
      setCartItems([]); // Ensure the state is reset on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchCart();
    }
  }, [token]);

  // Update Quantity
  const updateQuantity = async (itemId, variationId, quantity, price) => {
    console.log(`Sending data to backend: product_id: ${itemId}, prod_variation_id: ${variationId}, quantity: ${quantity}, price: ${price}`);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/add_to_cart`,
        {
          product_id: itemId,
          prod_variation_id: variationId,
          quantity,
          price,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('API Response:', response.data);
      fetchCart(); // Refetch cart after updating
    } catch (error) {
      console.error('Error updating quantity:', error.response?.data?.message || error.message);
    }
  };

  const increaseQuantity = (itemId) => {
    // Prevent duplicate calls by handling state carefully
    setCartItems((prevCartItems) =>
      prevCartItems.map((cartItem) =>
        cartItem.product_id === itemId
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    );
  
    // Find the item only once for the API update
    const item = cartItems.find((item) => item.product_id === itemId);
    if (item) {
      const newQuantity = item.quantity + 1;
      // Call API or update logic
      updateQuantity(itemId, item.prod_variation_id, newQuantity, item.sale_price);
    }
  };
  
  
  

  const decreaseQuantity = (itemId, variationId) => {
    const item = cartItems.find((item) => item.product_id === itemId);
    if (item && item.quantity > 1) {
      updateQuantity(item.product_id, item.prod_variation_id, item.quantity - 1, item.sale_price);
    } else if (!item) {
      console.error('Item not found:', itemId);
    } else {
      console.log('Minimum quantity reached for:', itemId);
    }
  };

  // Remove Item from Cart
  const removeFromCart = async (cartId) => {
    try {
      await axios.get(`${API_BASE_URL}/delete_cart_item/${cartId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCart(); // Refetch the cart after removing item
    } catch (error) {
      console.error('Error removing item from cart:', error.response?.data?.message || error.message);
    }
  };

  // Cart Calculations
  const totalMRP = cartItems.reduce(
    (acc, item) => acc + (item.regular_price || 0) * item.quantity,
    0
  );

  const subtotal = cartItems.reduce(
    (acc, item) => acc + (item.sale_price || 0) * item.quantity,
    0
  );
  const savings = totalMRP - subtotal;

  return (
    <MainLayOut>
      <div className="cart-page p-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-semibold">
          My Bag ({cartItems.length} Item{cartItems.length > 1 ? 's' : ''})
        </h2>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-blue-500"
              role="status"
            ></div>
          </div>
        ) : cartItems.length > 0 ? (
          <>
            <p className="text-green-600 my-2">Yay! You get FREE delivery on this order</p>
            <div className="flex">
              <div className="cart-items my-4 w-2/3">
                {cartItems.map((item) => (
                  <div
                    key={item.id}
                    className="cart-item flex items-start border-b pb-4 mb-4"
                  >
                    <div className="w-1/4 h-32 mr-4">
                      <img
                        src={item.primary_img}
                        alt={item.prod_name}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-md font-semibold">{item.prod_name}</h3>
                      <p className="text-gray-600 text-sm">Ships in 2-3 days</p>
                      {item.variation_name && (
                        <p className="text-gray-600 text-sm">Size: {item.variation_name}</p>
                      )}
                      <div className="flex items-center mt-2">
                        <button
                          onClick={() => decreaseQuantity(item.product_id, item.prod_variation_id)}
                          className="px-2 py-1 text-gray-700 bg-gray-200 rounded"
                        >
                          -
                        </button>
                        <span className="px-4">{item.quantity}</span>
                        {/* <button onClick={() => increaseQuantity(item.product_id)}>Increase</button> */}


                        <button
                          onClick={() => increaseQuantity(item.product_id, item.prod_variation_id)}
                          className="px-2 py-1 text-gray-700 bg-gray-200 rounded"
                        >
                          +
                        </button>
                      </div>
                      <div className="flex items-center gap-2 text-sm font-bold my-2">
                        <p className="text-black">₹{item.sale_price * item.quantity}</p>
                        {item.regular_price && (
                          <p className="text-gray-500 line-through">
                            ₹{item.regular_price * item.quantity}
                          </p>
                        )}
                      </div>
                      {item.regular_price > item.sale_price && (
                        <p className="text-green-500">
                          You saved ₹{(item.regular_price - item.sale_price) * item.quantity}
                        </p>
                      )}
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-red-500 text-sm underline mt-2"
                      >
                        Remove from Cart
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="price-summary p-4 border-t w-1/3">
                <h3 className="text-md font-semibold">PRICE SUMMARY</h3>
                <p className="text-gray-600 text-sm">Total MRP (Incl. of taxes): ₹{totalMRP}</p>
                <p className="text-gray-600 text-sm">Bag Discount: -₹{savings}</p>
                <p className="text-gray-600 text-sm">Delivery Fee: Free</p>
                <p className="font-bold text-black text-lg mt-2">Subtotal: ₹{subtotal}</p>
                <p className="text-green-600 mt-1">
                  You are saving a total of ₹{savings} on this order
                </p>
                <button className="mt-4 w-full bg-blue-500 text-white p-2 rounded">PROCEED</button>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center my-8">
            <p className="text-xl font-semibold">Your cart is empty</p>
            <p className="text-gray-600 mt-2">
              Looks like you haven't added anything to your cart yet.
            </p>

            <button
              onClick={() => navigate('/products')}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Continue Shopping
            </button>
          </div>
        )}
      </div>
    </MainLayOut>
  );
};

export default Cart;
