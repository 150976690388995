import React from 'react';
// import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <footer className="footer bg-black py-20">
    <div className='grid grid-cols-1 md:grid-cols-4 gap-8 py-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
  
  <div>
    <Link to="" className=' text-white mb-5 block uppercase text-4xl'>Clothology Footer</Link>
    <p className='text-white my-6 text-sm'>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit
    </p>
    
  </div>

  <div>
    <h5 className='text-gray-300 font-bold mb-3 text-nowrap text-sm'>COMPANY</h5>
    <ul>
      <li className='mb-2'><Link to="" className='text-white text-sm'>About Us</Link></li>
      <li className='mb-2'><Link to="" className='text-white text-sm'>We're Hiring</Link></li>
      <li className='mb-2'><Link to="" className='text-white text-sm'>Terms & Conditions</Link></li>
      <li className='mb-2'><Link to="" className='text-white text-sm'>Privacy Policy</Link></li>
      <li className='mb-2'><Link to="" className='text-white text-sm'>Return/Refund Policy</Link></li>
      <li className='mb-2'><Link to="" className='text-white text-sm'>FAQS</Link></li>
      <li className='mb-2'><Link to="" className='text-white text-sm'>Blogs</Link></li>
    </ul>
  </div>

  <div>
    {/* <h5 className='text-gray-300 font-bold mb-3'>CONNECT WITH US</h5> */}
    <div className='w-1/2 mb-4'>
      <h5 className='text-gray-300 font-bold mb-3 text-nowrap text-sm'>CUSTOMER SERVICE</h5>
      <ul>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Contact Us</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Track Order</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Return Order</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Cancel Order</Link></li>
      </ul>
    </div>
  </div>

  <div>
    <h5 className='text-gray-300 font-bold mb-3 text-nowrap text-sm'>QUICK LINK</h5>
    <ul>
        <li className='mb-2'><Link to="" className='text-white text-sm'>For Influencers</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Dealership/Franchise</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Interested in becoming partner store</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Reseller</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>For International sellers</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>For Corporates (B2B)</Link></li>
        <li className='mb-2'><Link to="" className='text-white text-sm'>Distributors</Link></li>
      </ul>
  </div>

</div>


    </footer>
      
    </>
  )
}

export default Footer
