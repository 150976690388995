import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { FaCartShopping, FaRegHeart, FaHeart } from "react-icons/fa6";
import axios from "axios";
import { useCart } from "./CartContext";

function ProductPageBody({ categorySlug }) {
  const [products, setProducts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const {token} = useCart();
  // const token = process.env.REACT_APP_USER_TOKEN;

  // Fetch products and filter data
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://tclserver.ertru.uk/api/v1/get_active_products",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with the actual token
            },
          }
        );
  
        if (response.data.status) {
          const allProducts = response.data.products;
          setProducts(
            categorySlug
              ? allProducts.filter((product) =>
                  product.product_categories.some(
                    (category) => category.slug === categorySlug
                  )
                )
              : allProducts
          );
  
          // Extract unique sizes and brands
          const uniqueSizes = [
            ...new Set(
              allProducts.flatMap((product) =>
                product.product_variations.map((variation) => variation.size)
              )
            ),
          ];
          const uniqueBrands = [
            ...new Set(allProducts.map((product) => product.brand_id)),
          ];
  
          setSizes(uniqueSizes);
          setBrands(uniqueBrands);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProducts();
  }, [categorySlug]);
  

  // Fetching the wishlist data
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.get('https://tclserver.ertru.uk/api/v1/get_customer_wishlist', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWishlistItems(response.data.wishlist || []); // Assuming the API returns a wishlist array
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    if (token) fetchWishlist();
  }, [token]);

  // Add to wishlist
  const addToWishlist = async (product) => {
    try {
      const response = await axios.post(
        'https://tclserver.ertru.uk/api/v1/add_to_wishlist',
        {
          product_id: product.id,
          quantity: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include Bearer token
          },
        }
      );
      console.log('Product added to wishlist:', response.data.message);
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
    }
  };
  
  

  // Remove item from wishlist
  const removeFromWishlist = async (productId) => {
    try {
      await axios.get(`https://tclserver.ertru.uk/api/v1/delete_wishlist_item/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== productId));
      console.log(`Item with ID ${productId} removed from wishlist`);
    } catch (error) {
      console.error('Error removing item from wishlist:', error);
    }
  };

  // Cart handling
  const handleAddToCart = useCallback(
    (product) => {
      const isInCart = cart.some((item) => item.id === product.id);
      if (!isInCart) {
        setCart([...cart, product]);
      } else {
        setCart(cart.filter((item) => item.id !== product.id));
      }
    },
    [cart]
  );

  if (loading) {
    return <div className="text-center py-8">Loading products...</div>;
  }

  return (
    <div className="product-page-body max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
      <div className="flex gap-3 text-xl mb-10">
        <h1 className="font-bold">
          Clothes for {categorySlug ? categorySlug.replace("-", " ") : "All Products"}
        </h1>
        <span className="text-gray-400"> ({products.length})</span>
      </div>

      <div className="flex justify-between">
        {/* Filter Sidebar */}
        <div className="filter sticky top-0 h-screen p-4">
          <div className="filterTitle mb-6 font-bold text-sm text-gray-400">
            Filters
          </div>
          <div className="filterOptions flex flex-col gap-4">
            <div>
              <h3 className="font-bold">Sizes</h3>
              {sizes.map((size, index) => (
                <div key={index} className="filterOption text-sm capitalize">
                  {size}
                </div>
              ))}
            </div>
            <div>
              <h3 className="font-bold">Brands</h3>
              {brands.map((brandId, index) => (
                <div key={index} className="filterOption text-sm capitalize">
                  Brand {brandId}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Product Listing */}
        <div className="all_products w-4/5 flex flex-wrap gap-4">
          {products.map((card) => (
            <div
              key={card.id}
              className="item w-[30%] bg-white overflow-hidden relative h-[450px] rounded-lg shadow-lg border border-gray-200"
            >
              {/* Product Image */}
              <Link to={`/p/${card.slug}`} className="relative w-full h-[70%]">
                <img
                  src={card.primary_img}
                  alt={card.prod_name}
                  className="w-full h-full object-cover transition-opacity duration-300 ease-in-out"
                />
              </Link>

              {/* Product Details */}
              <div className="p-4 absolute bottom-0 w-full bg-white bg-opacity-90">
                <div className="flex justify-between items-center">
                  <h3 className="text-sm text-gray-900 truncate w-4/5 font-semibold">
                    {card.prod_name}
                  </h3>

                  {/* Wishlist Button */}
                  <button
                    className={`text-gray-400 ${
                      wishlistItems.some((item) => item.id === card.id)
                        ? "text-red-500"
                        : "text-gray-400"
                    }`}
                    onClick={() => addToWishlist(card)}
                  >
                    {wishlistItems.some((item) => item.id === card.id) ? (
                      <FaHeart />
                    ) : (
                      <FaRegHeart />
                    )}
                  </button>

                {/* {wishlistItems.some((item) => item.id === card.id) ? (
                  <button
                    onClick={() => removeFromWishlist(card.id)}
                    className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
                  >
                    Remove from Wishlist
                  </button>
                ) : (
                  <button
                    onClick={() => addToWishlist(card)}
                    className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600"
                  >
                    Add to Wishlist
                  </button>
                )} */}

                  {/* Cart Button */}
                  <button
                    className={`text-gray-400 ${
                      cart.some((item) => item.id === card.id)
                        ? "text-green-500"
                        : "text-gray-400"
                    }`}
                    onClick={() => handleAddToCart(card)}
                  >
                    <FaCartShopping />
                  </button>
                </div>

                {/* Price and Discount */}
                {card.sale_price ? (
                  <div className="flex gap-2 text-sm font-bold my-2">
                    <p className="text-gray-500 line-through">₹ {card.regular_price}</p>
                    <p className="text-red-500">{card.sale_price}</p>
                  </div>
                ) : (
                  <p className="text-black my-2">₹ {card.regular_price}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductPageBody;
