import React from "react";
import MainLayOut from '../layout/MainLayOut';
import HeroSection from '../components/HeroSection';
import HomeBody from '../components/HomeBody';
import { Link } from 'react-router-dom';



const Home = () =>{
    return(
        <MainLayOut>
            <div className="justify-center p-4 flex items-center bg-gray-200 gap-10 extra_need">
                <Link>CLOO SALE</Link>
                <Link>SUPERCOMBED TEES</Link>
                <Link>OVERSIZED TEES</Link>
                <Link>CLOTHOLOGY GLOBAL SIGNATURE</Link>
                <Link>ACCESSORIES</Link>
            </div>
             <HeroSection />
             <HomeBody/>                      
        </MainLayOut>
    )
}

export default Home;