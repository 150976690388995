import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainLayOut from '../../layout/MainLayOut';

const Profile = () => {
  const [wishlist, setWishlist] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [orders, setOrders] = useState([]);
  const [activeMenu, setActiveMenu] = useState('Overview');
  const [profileData, setProfileData] = useState({
    firstName: 'test',
    lastName: '',
    email: 'kylefront74@gmail.com',
    mobile: '+916296600622',
    dob: '02-08-2024',
    gender: 'Male',
    whatsappUpdates: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/login');
      return;
    }
    const fetchWishlist = async () => {
      try {
        const response = await fetch('https://tclserver.ertru.uk/api/v1/get_customer_wishlist', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch wishlist');
        const data = await response.json();
        console.log(data);
        setWishlist(data.wishlist);
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    fetchWishlist();
  }, [navigate]);
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/login');
      return;
    }
  const fetchShipping = async () => {
    try {
      const response = await fetch('https://tclserver.ertru.uk/api/v1/get_customer_shipping_addresses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch shipping');
      const data = await response.json();
      console.log(data);
      setShipping(data.shipping_addresses);
    } catch (error) {
      console.error('Error fetching shipping:', error);
    }
  };

  fetchShipping();
}, [navigate]);

useEffect(() =>{
  const token = localStorage.getItem('userToken');
  fetch('https://tclserver.ertru.uk/api/v1/get_my_orders', {
    headers: {
      Authorization: `Bearer ${token}`
      }
  })
  .then(response => response.json())
  .then((data) => {
      if(data.status){
        setOrders(data.orders);
      }
    })
})

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/login');
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleSaveChanges = () => {
    // Save changes logic here
    console.log('Profile saved:', profileData);
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'Overview':
        return <div>Overview content goes here...</div>;
      case 'My Orders':
        return <div>
        {orders.length > 0 ? (
          <div>
            <h2>My Orders</h2>
            {orders.map((order) => (
              <div key={order.id} className="order-card">
                <h3>Order ID: {order.id}</h3>
                <p>
                  <strong>Customer:</strong> {order.customer.first_name} {order.customer.last_name}
                </p>
                <p>
                  <strong>Order Date:</strong> {order.order_date}
                </p>
                <p>
                  <strong>Total Amount:</strong> ₹{order.bill_amt}
                </p>
                <p>
                  <strong>Net Amount:</strong> ₹{order.net_amt}
                </p>
                <div className="order-items">
                  <h4>Order Items:</h4>
                  {order.order_items.map((item) => (
                    <div key={item.id} className="order-item">
                      <p>
                        <strong>Product:</strong> {item.product_name}
                      </p>
                      <p>
                        <strong>Quantity:</strong> {item.quantity}
                      </p>
                      <p>
                        <strong>Price:</strong> {item.price}
                      </p>
                      <p>
                        <strong>Total:</strong> ₹{item.total_price}
                      </p>
                    </div>
                  ))}
                </div>
                <p>
                  <strong>Shipping Status:</strong> {order.shipping_status}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p>Add Your Shipping Address</p>
        )}
      </div>;
      case 'My Payments':
        return <div>My Payments content goes here...</div>;
      case 'My Wallet':
        return <div>My Wallet content goes here...</div>;
      case 'My Addresses':
        return (
          <div>
          {shipping.length > 0 ? (
            <div className="flex flex-wrap gap-6">
            {shipping.map((item) => (
              <div key={item.id} className="w-full sm:w-1/2 lg:w-1/3 p-4 border rounded-lg shadow-md bg-white">
                <div className="font-semibold text-lg text-gray-800">{item.full_name}</div>
                <div className="text-sm text-gray-600">{item.address_label}</div>
                <div className="mt-2 text-gray-800">
                  <p>{item.address_line_1}</p>
                  {item.address_line_2 && <p>{item.address_line_2}</p>}
                  <p>{item.city}, {item.state} - {item.zip_code}</p>
                  {item.country && <p>{item.country}</p>}
                </div>
                <div className="mt-4 text-sm text-gray-500">
                  <p>Email: <span className="text-gray-800">{item.email}</span></p>
                  <p>Mobile: <span className="text-gray-800">{item.mobile_number}</span></p>
                  {item.alt_mob_number && (
                    <p>Alternate Mobile: <span className="text-gray-800">{item.alt_mob_number}</span></p>
                  )}
                </div>
                <div className="mt-4">
                  {item.is_default ? (
                    <span className="text-sm text-green-600 font-semibold">Default Address</span>
                  ) : (
                    <span className="text-sm text-gray-500">Not Default</span>
                  )}
                </div>
              </div>
            ))}
          </div>
          
          ) : (
            <p>Add Your Shipping Address</p>
          )}
        </div>
        );
      case 'My Wishlist':
        return (
          <div>
            {/* <h2>Your Wishlist</h2> */}
            {wishlist.length > 0 ? (
              <div className='flex flex-wrap gap-3'>
                {wishlist.map((item) => (
                  <Link className='w-1/3' key={item.id}>
                    <img src={item.product.primary_img} className='w-full h-[450px] object-cover'/>
                    {item.product.prod_name}
                  </Link>
                ))}
              </div>
            ) : (
              <p>No items in wishlist</p>
            )}
          </div>
        );
      case 'My Profile':
        return (
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">Profile Information</h3>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-700">First Name *</label>
                <input
                  type="text"
                  name="firstName"
                  value={profileData.firstName}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Email Id *</label>
                <input
                  type="email"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700">Mobile Number *</label>
                <input
                  type="tel"
                  name="mobile"
                  value={profileData.mobile}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <button className="text-blue-500 mt-2">CHANGE</button>
              </div>
              <div>
                <label className="block text-gray-700">DOB</label>
                <input
                  type="date"
                  name="dob"
                  value={profileData.dob}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <p className="text-sm text-gray-500">
                  Share your DOB to get special gifts on the 1st day of your birthday month
                </p>
              </div>
              <div>
                <label className="block text-gray-700">Gender</label>
                <select
                  name="gender"
                  value={profileData.gender}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="whatsappUpdates"
                  checked={profileData.whatsappUpdates}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                <label>I want to receive order updates on WhatsApp</label>
              </div>
              <button
                type="button"
                onClick={handleSaveChanges}
                className="px-4 py-2 bg-blue-500 text-white rounded mt-4"
              >
                SAVE CHANGES
              </button>
            </form>
          </div>
        );
      case 'Logout':
        handleLogout();
        break;
      default:
        return <div>Select a menu item</div>;
    }
  };

  return (
    <MainLayOut>
      <div className='bg-gray-100 py-12'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
      <div className="flex bg-gray-100">
        {/* Sidebar */}
        <div className="w-1/4 bg-white shadow-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">My Account</h2>
          <ul className="space-y-4">
            {['Overview', 'My Orders', 'My Payments', 'My Wallet', 'My Addresses', 'My Wishlist', 'My Profile', 'Logout'].map((item) => (
              <li
                key={item}
                className={`p-2 rounded-lg cursor-pointer text-gray-700 hover:bg-gray-200 ${
                  activeMenu === item ? 'bg-gray-200 font-semibold' : ''
                }`}
                onClick={() => setActiveMenu(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 p-8">
          <h3 className="text-2xl font-semibold mb-4">{activeMenu}</h3>
          <div className="p-4 bg-white rounded-lg shadow-md">
            {renderContent()}
          </div>
        </div>
      </div>
      </div>
      </div>
    </MainLayOut>
  );
};

export default Profile;
