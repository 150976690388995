import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(localStorage.getItem('userToken'));
  const webToken = process.env.REACT_APP_WEB_TOKEN; // Default token for unauthenticated users
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (userToken) {
      localStorage.setItem('userToken', userToken);
    } else {
      localStorage.removeItem('userToken');
    }
  }, [userToken]);

  useEffect(() => {
    if (apiUrl) {
      localStorage.setItem('apiurl', apiUrl);
    } else {
      localStorage.removeItem('apiurl');
    }
  }, [apiUrl]);

  const login = (token) => {
    setUserToken(token);
    navigate('/wishlist');
  };

  const logout = () => {
    setUserToken(null);
    navigate('/login');
  };

  const getToken = () => userToken || webToken;

  return (
    <AuthContext.Provider value={{ userToken, webToken, login, logout, getToken, apiUrl }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
