import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayOut from '../layout/MainLayOut';

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);

  const userToken = process.env.REACT_APP_USER_TOKEN;

  // Fetch Wishlist Data from API
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await axios.get('https://tclserver.ertru.uk/api/v1/get_customer_wishlist', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setWishlistItems(response.data.wishlist || []); // Assuming API returns wishlist array
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    fetchWishlist();
  }, [userToken]);

  // Remove item from Wishlist
  const removeFromWishlist = async (productId) => {
    try {
      await axios.get(`https://tclserver.ertru.uk/api/v1/delete_wishlist_item/${productId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== productId));
      console.log(`Item with ID ${productId} removed from wishlist`);
    } catch (error) {
      console.error('Error removing item from wishlist:', error);
    }
  };

  // Debugging
  console.log('Wishlist Items in Wishlist Component:', wishlistItems);

  return (
    <MainLayOut>
      <div className="wishlist-page p-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-semibold">
          My Wishlist ({wishlistItems.length} Item{wishlistItems.length !== 1 ? 's' : ''})
        </h2>
        {wishlistItems.length > 0 ? (
          <div className="wishlist-items my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {wishlistItems.map((item) => (
              <div key={item.id} className="wishlist-item border p-4 rounded-lg">
                <img
                  src={item.product.primary_img}
                  alt={item.product.prod_name}
                  className="w-full h-48 object-cover rounded-lg"
                />
                <h3 className="text-md font-semibold mt-2">{item.product.prod_name}</h3>
                <p className="text-gray-600">₹{item.product.sale_price || item.product.regular_price}</p>
                <button
                  onClick={() => removeFromWishlist(item.id)}
                  className="mt-2 text-red-500 text-sm underline"
                >
                  Remove from Wishlist
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center my-8 text-gray-600">Your wishlist is empty!</p>
        )}
      </div>
    </MainLayOut>
  );
};

export default Wishlist;
