import './App.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import React from 'react';
import Base from './pages/Base';
import Checkout from './pages/Checkout';
import ProductPage from './pages/ProductPage';
import ProductDetails from './pages/ProductDetails';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/user/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

import Login from './components/user/Login';
import Register from './components/user/Register';
import Profile from './components/user/Profile';
import Wishlist from './pages/Wishlist';  // Correct import for the Wishlist page component
import Cart from './pages/Cart';


function App() {
    return (
      
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/wishlist" element={<ProtectedRoute><Wishlist /></ProtectedRoute>} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/u/profile" element={<Profile />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Base />} />
            <Route path="/products" element={<ProductPage showAll={true} />} />
            <Route path="/:categorySlug" element={<ProductPage />} />
            <Route path="/p/:slug" element={<ProductDetails />} />
          </Routes>
        </AuthProvider>
      
  );
  
}

export default App;
