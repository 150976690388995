import React from 'react'
import { Link } from 'react-router-dom'

function NavMenus() {
  return (
    <>
      <nav className="ml-10 flex items-center space-x-8">
                <div className='group'>
                    <Link to="/men" className="text-gray-900 hover:text-black pb-[25px] uppercase">Men</Link>
                    <span className='absolute hidden group-hover:flex group-hover:top-[65px] group-hover:opacity-100 transition duration-1000 ease-in-out gap-5 bg-white p-12 text-nowrap left-[7%] right-[7%] top-[95px] opacity-0'>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Topwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>T-Shirts</li>
                                  <li>Printed T-Shirts</li>
                                  <li>Oversized T-shirts</li>
                                  <li>Classic Fit T-shirts</li>
                                  <li>Plain T-Shirts</li>
                                  <li>Half Sleeve T-Shirts</li>
                                  <li>Polo T-Shirts</li>
                                  <li>Vests</li>
                                  <li>Shirts</li>
                                  <li>Co-ord Sets</li>
                                  <li>Full Sleeve T-Shirts</li>
                                  <li>Plus Size Topwear</li>
                                  <li>Customize T-shirts</li>
                                  <li>Sweatshirts & Hoodies</li>
                                  <li>Jackets</li>
                                  <li>Sweaters</li>
                                  <li>All Topwear</li>
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Bottomwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Joggers</li>
                                  <li>Jeans</li>
                                  <li>Baggy Jeans</li>
                                  <li>Pajamas</li>
                                  <li>Cargos</li>
                                  <li>Cargo Pants</li>
                                  <li>Trousers & Pants</li>
                                  <li>Parachute Pants</li>
                                  <li>Co-ord Sets</li>
                                  <li>Shorts</li>
                                  <li>Boxers</li>
                                  <li>Combos</li>
                                  <li>Plus Size Bottomwear</li>
                                  <li>All Bottomwear</li>
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Top Sellers</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Top 70 T-Shirts</li>
                                  <li>Top 10 Cargos</li>
                                  <li>Top 10 Jeans</li>
                                  <li>Top 20 Joggers</li>
                                  <li>Top 10 Shirts</li>
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Winterwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Sweatshirts & Hoodies</li>
                                  <li>Jackets</li>
                                  <li>Sweaters</li>
                                  <li>Joggers</li>
                                  <li>Plus Size</li>
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Footwear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Bewakoof Sneakers</li>
                                  <li>Sliders</li>
                                  <li>Casual Shoes</li>
                                </ul>
                              </li>
                        </ul>
                        <ul>
                              <li><h4 className='my-3 font-medium'>Accessories</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Mobile covers</li>
                                  <li>Backpacks</li>
                                  <li>Sunglasses</li>
                                  <li>Sling bags</li>
                                  <li>Caps</li>
                                  <li>Mobile Card-holder</li>
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Plus Size</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>T-shirts</li>
                                  <li>Shirts</li>
                                  <li>Jackets</li>
                                  <li>Sweatshirts & Hoodies</li>
                                  <li>Bottomwear</li>
                                  <li>Jeans</li>
                                </ul>
                              </li>
                              <li><h4 className='my-3 font-medium'>Innerwear & Loungewear</h4>
                                <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                  <li>Vests</li>
                                  <li>Pajamas</li>
                                  <li>Boxers</li>
                                </ul>
                              </li>
                        </ul>
                    </span>                   
                </div>
                <div className='group'>
                <Link to="/" className="text-gray-900 hover:text-gray-700 group-hover:border-b-red-700 pb-[25px]">Women</Link>
                      <span className='absolute hidden group-hover:flex group-hover:top-[65px] group-hover:opacity-100 transition duration-1000 ease-in-out gap-5 bg-white p-12 text-nowrap left-[7%] right-[7%] top-[95px] opacity-0'>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Topwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>T-Shirts</li>
                                    <li>Printed T-Shirts</li>
                                    <li>Oversized T-shirts</li>
                                    <li>Classic Fit T-shirts</li>
                                    <li>Plain T-Shirts</li>
                                    <li>Half Sleeve T-Shirts</li>
                                    <li>Polo T-Shirts</li>
                                    <li>Vests</li>
                                    <li>Shirts</li>
                                    <li>Co-ord Sets</li>
                                    <li>Full Sleeve T-Shirts</li>
                                    <li>Plus Size Topwear</li>
                                    <li>Customize T-shirts</li>
                                    <li>Sweatshirts & Hoodies</li>
                                    <li>Jackets</li>
                                    <li>Sweaters</li>
                                    <li>All Topwear</li>
                                  </ul>
                                </li>
                          </ul>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Bottomwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Joggers</li>
                                    <li>Jeans</li>
                                    <li>Baggy Jeans</li>
                                    <li>Pajamas</li>
                                    <li>Cargos</li>
                                    <li>Cargo Pants</li>
                                    <li>Trousers & Pants</li>
                                    <li>Parachute Pants</li>
                                    <li>Co-ord Sets</li>
                                    <li>Shorts</li>
                                    <li>Boxers</li>
                                    <li>Combos</li>
                                    <li>Plus Size Bottomwear</li>
                                    <li>All Bottomwear</li>
                                  </ul>
                                </li>
                          </ul>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Top Sellers</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Top 70 T-Shirts</li>
                                    <li>Top 10 Cargos</li>
                                    <li>Top 10 Jeans</li>
                                    <li>Top 20 Joggers</li>
                                    <li>Top 10 Shirts</li>
                                  </ul>
                                </li>
                                <li><h4 className='my-3 font-medium'>Winterwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Sweatshirts & Hoodies</li>
                                    <li>Jackets</li>
                                    <li>Sweaters</li>
                                    <li>Joggers</li>
                                    <li>Plus Size</li>
                                  </ul>
                                </li>
                                <li><h4 className='my-3 font-medium'>Footwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Bewakoof Sneakers</li>
                                    <li>Sliders</li>
                                    <li>Casual Shoes</li>
                                  </ul>
                                </li>
                          </ul>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Accessories</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Mobile covers</li>
                                    <li>Backpacks</li>
                                    <li>Sunglasses</li>
                                    <li>Sling bags</li>
                                    <li>Caps</li>
                                    <li>Mobile Card-holder</li>
                                  </ul>
                                </li>
                                <li><h4 className='my-3 font-medium'>Plus Size</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>T-shirts</li>
                                    <li>Shirts</li>
                                    <li>Jackets</li>
                                    <li>Sweatshirts & Hoodies</li>
                                    <li>Bottomwear</li>
                                    <li>Jeans</li>
                                  </ul>
                                </li>
                                <li><h4 className='my-3 font-medium'>Innerwear & Loungewear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Vests</li>
                                    <li>Pajamas</li>
                                    <li>Boxers</li>
                                  </ul>
                                </li>
                          </ul>
                      </span>    
                </div>
                <div className='group'>
                <Link to="/" className="text-gray-900 hover:text-gray-700 group-hover:border-b-red-700 pb-[25px]">New Arrivals</Link>
                      <span className='absolute hidden group-hover:flex group-hover:top-[65px] group-hover:opacity-100 transition duration-1000 ease-in-out gap-5 bg-white p-12 text-nowrap left-[7%] right-[7%] top-[95px] opacity-0'>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Topwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>T-Shirts</li>
                                    <li>Printed T-Shirts</li>
                                    <li>Oversized T-shirts</li>
                                    <li>Classic Fit T-shirts</li>
                                    <li>Plain T-Shirts</li>
                                    <li>Half Sleeve T-Shirts</li>
                                    <li>Polo T-Shirts</li>
                                    <li>Vests</li>
                                    <li>Shirts</li>
                                    <li>Co-ord Sets</li>
                                    <li>Full Sleeve T-Shirts</li>
                                    <li>Plus Size Topwear</li>
                                    <li>Customize T-shirts</li>
                                    <li>Sweatshirts & Hoodies</li>
                                    <li>Jackets</li>
                                    <li>Sweaters</li>
                                    <li>All Topwear</li>
                                  </ul>
                                </li>
                          </ul>
                          
                          <ul>
                                <li><h4 className='my-3 font-medium'>Top Sellers</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Top 70 T-Shirts</li>
                                    <li>Top 10 Cargos</li>
                                    <li>Top 10 Jeans</li>
                                    <li>Top 20 Joggers</li>
                                    <li>Top 10 Shirts</li>
                                  </ul>
                                </li>
                                <li><h4 className='my-3 font-medium'>Winterwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Sweatshirts & Hoodies</li>
                                    <li>Jackets</li>
                                    <li>Sweaters</li>
                                    <li>Joggers</li>
                                    <li>Plus Size</li>
                                  </ul>
                                </li>
                                <li><h4 className='my-3 font-medium'>Footwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Bewakoof Sneakers</li>
                                    <li>Sliders</li>
                                    <li>Casual Shoes</li>
                                  </ul>
                                </li>
                          </ul>
                          
                      </span>    
                </div>
                <div className='group'>
                <Link to="/" className="text-gray-900 hover:text-gray-700 group-hover:border-b-red-700 pb-[25px]">Sale</Link>
                      <span className='absolute hidden group-hover:flex group-hover:top-[65px] group-hover:opacity-100 transition duration-1000 ease-in-out gap-5 bg-white p-12 text-nowrap left-[7%] right-[7%] top-[95px] opacity-0'>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Topwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>T-Shirts</li>
                                    <li>Printed T-Shirts</li>
                                    <li>Oversized T-shirts</li>
                                    <li>Classic Fit T-shirts</li>
                                    <li>Plain T-Shirts</li>
                                    <li>Half Sleeve T-Shirts</li>
                                    <li>Polo T-Shirts</li>
                                    <li>Vests</li>
                                    <li>Shirts</li>
                                    <li>Co-ord Sets</li>
                                    <li>Full Sleeve T-Shirts</li>
                                    <li>Plus Size Topwear</li>
                                    <li>Customize T-shirts</li>
                                    <li>Sweatshirts & Hoodies</li>
                                    <li>Jackets</li>
                                    <li>Sweaters</li>
                                    <li>All Topwear</li>
                                  </ul>
                                </li>
                          </ul>
                          <ul>
                                <li><h4 className='my-3 font-medium'>Bottomwear</h4>
                                  <ul className='text-sm flex flex-col gap-2 text-gray-600'>
                                    <li>Joggers</li>
                                    <li>Jeans</li>
                                    <li>Baggy Jeans</li>
                                    <li>Pajamas</li>
                                    <li>Cargos</li>
                                    <li>Cargo Pants</li>
                                    <li>Trousers & Pants</li>
                                    <li>Parachute Pants</li>
                                    <li>Co-ord Sets</li>
                                    <li>Shorts</li>
                                    <li>Boxers</li>
                                    <li>Combos</li>
                                    <li>Plus Size Bottomwear</li>
                                    <li>All Bottomwear</li>
                                  </ul>
                                </li>
                          </ul>
                      </span>    
                </div>
              </nav>
    </>
  )
}

export default NavMenus
