// Breadcrumb.js
import React from 'react';
import { FaAngleRight, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Breadcrumb({ category, productName }) {
  return (
    <nav className="breadcrumb text-gray-600 text-sm max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 py-12">
      <ul className="flex space-x-2 align-middle">
        <li>
          <Link to="/" className="hover:underline">
            Home
          </Link>
        </li>
            <FaAngleRight className='text-[22px] opacity-35'/>
        <li>
          <Link to="/products" className="hover:underline">
            {category || "Category"}
          </Link>
        </li>
        <FaAngleRight className='text-[22px] opacity-35'/>
        <li className="text-gray-800 font-semibold">
          {productName || "Product Name"}
        </li>
      </ul>
    </nav>
  );
}

export default Breadcrumb;
